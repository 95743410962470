import React from "react"
import { Link } from 'gatsby'
import SinglePageLayout from '../components/SinglePageLayout';
import SEO from "../components/seo"

const NotFoundPage = () => (
  <SinglePageLayout>
    <SEO title="404: Page Not Found" />
    <div className="container-page">
        <div className="page-hero hero">
            <h1 className="page-title">404: Page Not Found</h1>
            <p className="page-desc">We're sorry! Looks like the page does not exist.</p>
        </div>
        <div className="page-content text-center">
          <h3 className="text-center"> Go back to homepage:</h3>
          <div className="py-5 my-1">
            <Link to="/" className="btn">Home</Link>
          </div>
        </div>
    </div>
  </SinglePageLayout>
)

export default NotFoundPage
